import { createApp } from 'vue';
import App from './App.vue';
import QuestionsPage from "./components/QuestionsPage.vue";
import Keycloak from 'keycloak-js';
import log from './services/Logger';
import { createRouter, createWebHashHistory } from 'vue-router';
import axios from "axios";
let initOptions = {
    url: 'https://id.groundlift.live/auth',
    realm: 'groundliftlive',
    clientId: 'feedback-viewer'
};
let keycloak = Keycloak(initOptions);
let apiUrl = "https://api.groundlift.live/api/v1";
let wsBrokerUrl = "wss://websocketbroker.groundlift.live/websocket";
keycloak.init({ onLoad: "login-required" }).then((auth) => {
    if (!auth) {
        console.log("Authenticating");
        window.location.reload();
    }
    else {
        console.log("Authenticated");
        axios.defaults.baseURL = apiUrl;
        axios.defaults.headers.common['Authorization'] = "Bearer " + keycloak.token;
        keycloak.onAuthRefreshSuccess = function () {
            console.log("Updating token in Axios");
            axios.defaults.headers.common['Authorization'] = "Bearer " + keycloak.token;
        };
        const router = createRouter({
            history: createWebHashHistory(),
            routes: [
                {
                    path: '/',
                    component: App
                },
                {
                    path: '/questions/:eventId',
                    component: QuestionsPage,
                    props: route => ({
                        "wsBrokerUrl": wsBrokerUrl,
                        "eventId": route.params.eventId,
                        "keycloak": keycloak
                    })
                }
            ]
        });
        createApp({})
            .use(log)
            .use(router)
            .provide("keycloak", keycloak)
            .mount('#app');
    }
    //Token Refresh
    setInterval(() => {
        keycloak.updateToken(70).then((refreshed) => {
            if (refreshed) {
                log.info('Token refreshed' + refreshed);
            }
            else {
                let exp = keycloak.tokenParsed ? keycloak.tokenParsed.exp : 0;
                log.warn('Token not refreshed, valid for '
                    + Math.round((exp || 0) + (keycloak.timeSkew || 0) - new Date().getTime() / 1000) + ' seconds');
            }
        }).catch(() => {
            log.error('Failed to refresh token');
        });
    }, 6000);
}).catch(() => {
    log.error("Authenticated Failed");
});
