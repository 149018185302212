import { defineComponent } from 'vue';
import axios from "axios";
import draggable from "vuedraggable";
import { Client } from "@stomp/stompjs";
import moment from "moment";
export default defineComponent({
    name: "QuestionsPage",
    props: ["eventId", "wsBrokerUrl", "keycloak"],
    components: {
        draggable
    },
    data() {
        return {
            questions: Array(),
            completedQuestions: Array(),
            stompClient: Object(),
            connected: false,
            windowInnerHeight: 0
        };
    },
    computed: {
        applicationIsInFullscreenMode() {
            return this.windowInnerHeight >= screen.availHeight;
        },
        totalQuestions() {
            return this.questions.length + this.completedQuestions.length;
        }
    },
    watch: {},
    methods: {
        markItem(qarray, index, state) {
            console.log("Marking item " + index + " as " + state);
            qarray[index].state = state;
            axios.patch("/events/" + this.eventId + "/questions/" + qarray[index].id, qarray[index]);
            qarray.splice(index, 1);
        },
        connect() {
            this.stompClient = new Client();
            this.stompClient.configure({
                brokerURL: this.wsBrokerUrl + "?access_token=" + this.keycloak.token,
                debug: function (str) {
                    console.log(str);
                },
                reconnectDelay: 2000,
                heartbeatIncoming: 10000,
                heartbeatOutgoing: 10000
            });
            this.stompClient.onConnect = this.onConnect;
            this.stompClient.activate();
        },
        onConnect(frame) {
            console.log('Connected: ' + frame);
            this.stompClient.subscribe("/topic/questions/" + this.eventId, this.onQuestionMessage);
        },
        onQuestionMessage(stompMessage) {
            var message = JSON.parse(stompMessage.body);
            console.log(message);
            if (message.state == "NEW") {
                // Remove completed question
                let index = this.completedQuestions.findIndex(q => { return q.id === message.id; });
                if (index >= 0) {
                    this.completedQuestions.splice(index, 1);
                }
                // Add/Update Question
                let exQuestion = this.questions.find(q => { return q.id === message.id; });
                if (exQuestion) {
                    exQuestion.rank = message.rank;
                    exQuestion.question = message.question;
                    exQuestion.state = message.state;
                    exQuestion.createdDate = message.createdDate;
                    exQuestion.createdBy = message.createdBy;
                    exQuestion.lastModifiedDate = message.lastModifiedDate;
                    exQuestion.lastModifiedBy = message.lastModifiedBy;
                }
                else {
                    this.questions.push({
                        id: message.id,
                        rank: message.rank,
                        question: message.question,
                        state: message.state,
                        createdDate: message.createdDate,
                        createdBy: message.createdBy,
                        lastModifiedDate: message.lastModifiedDate,
                        lastModifiedBy: message.lastModifiedBy
                    });
                }
                this.questions.sort((a, b) => { return b.rank - a.rank; });
            }
            else {
                // Remove question
                let index = this.questions.findIndex(q => { return q.id === message.id; });
                if (index >= 0) {
                    this.questions.splice(index, 1);
                }
                // Add/update completed questions
                let exQuestion = this.completedQuestions.find(q => { return q.id === message.id; });
                if (exQuestion) {
                    exQuestion.rank = message.rank;
                    exQuestion.question = message.question;
                    exQuestion.state = message.state;
                    exQuestion.createdDate = message.createdDate;
                    exQuestion.createdBy = message.createdBy;
                    exQuestion.lastModifiedDate = message.lastModifiedDate;
                    exQuestion.lastModifiedBy = message.lastModifiedBy;
                }
                else {
                    this.completedQuestions.unshift({
                        id: message.id,
                        rank: message.rank,
                        question: message.question,
                        state: message.state,
                        createdDate: message.createdDate,
                        createdBy: message.createdBy,
                        lastModifiedDate: message.lastModifiedDate,
                        lastModifiedBy: message.lastModifiedBy
                    });
                }
            }
        },
        onReorder() {
            let rank = 1;
            this.questions.slice().reverse().forEach(function (q) {
                q.rank = rank;
                rank++;
            });
            axios.patch("/events/" + this.eventId + "/questions", this.questions);
        },
        formatTime(time) {
            return moment(time).calendar();
        }
    },
    mounted() {
        console.log("Loading questions");
        const vm = this;
        axios
            .get("/events/" + this.eventId + "/questions")
            .then(function (value) {
            vm.$data.questions = value.data.filter((q) => q.state == "NEW");
            vm.$data.completedQuestions = value.data.filter((q) => q.state != "NEW");
        });
        this.connect();
        window.addEventListener('resize', () => {
            vm.windowInnerHeight = window.innerHeight;
        });
        moment.locale("de");
    }
});
